import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const resolveToggle = (envVar, defaultValue) => {
    if (envVar === undefined) {
        return defaultValue
    }
    return envVar.toLowerCase() === 'true'
}

const toggles = {
    unlockSite: resolveToggle(process.env.REACT_APP_UNLOCK_SITE, true),
    useHostedBookingPage: resolveToggle(process.env.REACT_APP_USE_HOSTED_BOOKING, true)
}

ReactDOM.render(
    <React.StrictMode>
        <App toggles={toggles} />
    </React.StrictMode>,
    document.getElementById('root')
)
