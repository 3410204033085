import React from 'react'
import {Link} from 'react-router-dom'

export default function Landing() {
	return (
		<div className='Error' style={{marginInline: '5%', marginBlockStart: 60}}>
			<h4 align='center' style={{fontWeight: 'bold'}}>Welcome to Sweetheart Beauty Bar</h4>
			<div align='center'>
				This site is under construction, but you can still book an appointment <Link to='/booking'>here</Link>.
			</div>
		</div>
	)
}
