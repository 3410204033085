import React from 'react'
import {Link} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import sweetheartLogo from '../assets/sweetheart-logo.png'
import './NavBar.scss'

export default function NavBar() {
    return (
        <div className='NavBar'>
            <Container fluid>
                <Row md={12} style={{alignItems: 'center'}}>
                    <Col align='center' md='auto'>
                        <Link to='/'>
                            <img id='home-logo' src={sweetheartLogo} alt='Sweetheart Beauty Bar' />
                        </Link>
                    </Col>
                    <Col align='center' xs='auto' md>
                        <div id='nav-links'>
                            <Link className='label' to='/lashes'>Lashes</Link>
                            <Link className='label nav-item-end' to='/nails'>Nails</Link>
                        </div>
                    </Col>
                    <Col align='end' xs md='auto'>
                        <Link className='label' id='booking-btn' to='/booking' type='button'>Book here</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
