import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import facebookIcon from '../assets/facebook_icon.svg'
import instagramIcon from '../assets/instagram_icon.svg'
import './Footer.scss'

export default function Footer() {
	return (
		<div className='Footer'>
			<Container fluid>
				<Row>
					<Col xs={12} sm={4} className='Footer-contact'>
						<h4 className='label'>Contact</h4>
						<p>815-793-6784 (text)</p>
					</Col>
					<Col xs={{span: 12, order: 'last'}} sm={4} className='Footer-follow'>
						<h4 className='label'>Follow</h4>
						<a href='https://www.facebook.com/sweetheartbeautybarbyvi' rel='noreferrer' target='_blank'>
							<img className='Footer-media-icon fb' src={facebookIcon} alt='facebook'/>
						</a>
						<a href='https://www.instagram.com/sweetheartbeautybar' rel='noreferrer' target='_blank'>
							<img className='Footer-media-icon insta' src={instagramIcon} alt='instagram'/>
						</a>
					</Col>
					<Col xs={12} sm={{span: 4, order: 'last'}} className='Footer-visit'>
						<h4 className='label'>Visit</h4>
						<p>1806 W Cuyler Ave, Suite 3F<br/>
							Chicago, IL 60613</p>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
