import React from 'react'
import home_face from '../../assets/home_face.png'
import './Home.scss'

export default function Home() {
    return (
        <div className='Home'>
            <div id='home-pink-bg'>
                <div id='home-pink-section' align='center'>
                    <img id='home-headshot-image' src={home_face} align='center' alt='client headshot'/>
                </div>
            </div>
        </div>
    )
}
