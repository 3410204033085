import React from 'react'
import StyleBox from '../StyleBox'
import classicExample from '../../assets/lash-classic.jpeg'
import sweetheartClassicExample from '../../assets/lash-sweetheart-classic.jpeg'
import hybridExample from '../../assets/lash-hybrid.jpeg'
import volumeExample from '../../assets/lash-volume.jpeg'
import megavolumeExample from '../../assets/lash-megavolume.jpeg'
import animeExample from '../../assets/lash-anime.jpeg'
import wetExample from '../../assets/lash-wet.jpeg'

export default function LashServices() {
	const introParagraph = `Eyelash extensions are semi-permanent lashes that are applied on top of your natural,
        isolated lash to ensure the growth and health cycle of your real eyelash. Unlike strip lashes, eyelash
        extensions are customizable so you can achieve a natural look or you can have eyelashes that are big and fluffy
        to your liking. With proper care the lashes can last for six to eight weeks until they naturally fall out with
        your lash cycle.`
	const classicDescription = `Classic style is when a single extension is applied to one isolated natural lash.
		Classic lashes are perfect for adding length and subtle fullness to your natural lash line.`
	const sweetheartClassicDescription = `The signature Sweetheart Classic is a unique set of mostly one lash extension
		on one natural lash but with an addition of very small lightweight narrow micro fans added to the mix to create
		the most fluttery wispy texture. This set is the most angelic and natural. `
	const hybridDescription = `Hybrid lashes are a mixture of classic and volume lashes. Hybrids give your eyes the
		best of both worlds for those who prefer natural but still want some drama.`
	const volumeDescription = `Volume is a technique used to achieve a fuller, fluffier look. Between 3-8 super fine
		extension fans are applied to a single natural lash. Despite the added drama, volume lashes are still safe
		for your natural lashes.`
	const megavolumeDescription = `Megavolume is a technique used to achieve the fullest look. Between 10-15 super
		fine extension fans are applied to a single natural lash. This set is the most dramatic and recommended for
		clients looking to go all out.`
	const animeDescription = `Anime lash extensions are a unique style of lash extensions that use spiky lash strands
		with volume fans between each spike to create an exaggerated doll-like look similar to that of female anime and
		manga characters.`
	const wetDescription = `The wet lash set uses closed fans technique to mimic a mascara look. This set is desirable
		for those who wants a thicker lash line and a bolder more dramatic classic look.`
	// TODO AA last want to change the font with the style name above the photos
	// TODO AA last add prices to all services
	return (
		// TODO AA Might want to combine the CSS/make a common w/ NailServices.
		// TODO AA collapsible things, react-collapsed
		<div className='LashServices page-content-wrapper'>
			<div className='page-content'>
				<div id='lashes-intro'>
					<h1>Lash Extensions</h1>
					<p>{introParagraph}</p>
				</div>
				<h1>Styles</h1>
				<div className='services-style-gallery'>
					<StyleBox
						title='Classic'
						imageSrc={classicExample}
						imageAlt='Classic lashes'
						description={classicDescription} />
					<StyleBox
						title='Sweetheart Classic'
						imageSrc={sweetheartClassicExample}
						imageAlt='Sweetheart Classic lashes'
						description={sweetheartClassicDescription} />
					<StyleBox
						title='Hybrid'
						imageSrc={hybridExample}
						imageAlt='Hybrid lashes'
						description={hybridDescription} />
					<StyleBox
						title='Wet/Mascara'
						imageSrc={wetExample}
						imageAlt='Wet lashes'
						description={wetDescription} />
					<StyleBox
						title='Volume'
						imageSrc={volumeExample}
						imageAlt='Volume lashes'
						description={volumeDescription} />
					<StyleBox
						title='Megavolume'
						imageSrc={megavolumeExample}
						imageAlt='Megavolume lashes'
						description={megavolumeDescription} />
					<StyleBox
						title='Anime'
						imageSrc={animeExample}
						imageAlt='Anime lashes'
						description={animeDescription} />
				</div>
				<div>
					<h1>After Care</h1>
					<h4 className='small-text' style={{fontStyle: 'italic'}}>
						Please follow to ensure long-lasting results
					</h4>
					<ul type='disc'>
						<li>Keep dry for the next 24 hours</li>
						<li>Avoid heavy makeup/waterproof products</li>
						<li>Don't pull or pick on them</li>
						<li>Use lash shampoo daily</li>
						<li>Sleep on your back or invest in silk pillowcase</li>
						<li>Keep oils away from the eye area</li>
						<li>Do not use eyelash curler</li>
						<li>Brush lashes often</li>
						<li>Have lashes professionally removed</li>
						<li>Book a refill every 2-3 weeks for optimal upkeep</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
