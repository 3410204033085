import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Home from './components/pages/Home'
import LashServices from './components/pages/LashServices'
import NailServices from './components/pages/NailServices'
import EmbeddedSchedule from './components/pages/EmbeddedSchedule'
import Error from './components/pages/Error'
import Landing from './components/pages/Landing'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import './App.scss'

export default function App(props) {
    return (
        <div className='App' >
            <Router>
                <div className='App-grid-wrapper'>
                    <div>
                        <NavBar />
                        <div className='content-body'>
                            <Switch>
                                <Route path='/booking' component={props.toggles.useHostedBookingPage ?
                                    EmbeddedSchedule : () => {
                                        window.location.replace('https://sweetheartbeautybar.as.me')
                                        return null
                                    }
                                }/>
                                {props.toggles.unlockSite || <Route path='/' component={ Landing } />}
                                <Route path='/' exact component={ Home } />
                                <Route path='/lashes' component={ LashServices } />
                                <Route path='/nails' component={ NailServices } />
                                <Route path='/error' component={ Error } />
                                <Route path='/home'>
                                    <Redirect to='/' />
                                </Route>
                                <Route path='/'>
                                    <Redirect to='/error' />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Router>
        </div>
    )
}
