import React from 'react'
import './StyleBox.scss'

export default function StyleBox(props) {
	return (
		<div className='StyleBox'>
			<h1>{props.title}</h1>
			<img src={props.imageSrc} alt={props.imageAlt} />
			<p>{props.description}</p>
		</div>
	)
}