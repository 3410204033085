import React from 'react'
import StyleBox from '../StyleBox'
import noChipExample from '../../assets/nail-nochip.jpeg'
import builderExample from '../../assets/nail-builder.jpeg'
import acrylicExample from '../../assets/nail-acrylic.jpeg'
import gelExample from '../../assets/nail-gel.jpeg'
import './Services.scss'

export default function NailServices() {
	const noChipDescription = `Includes soak off of previous gel polish, cuticle work, and polish of choice cured under
		LED light. This manicure should last 2-3 weeks.`
	const builderDescription = `Builder gel is a thicker more structured gel applied to add strength and to rebalance
		the shape of the natural nails. This set is a more natural looking alternative to acrylic and great for those
		with an allergy to acrylic powder products.`
	const acrylicDescription = `Acrylic nails are created using a powder and liquid monomer to provide strength and
		structure. This is the most common form of nail extension. `
	const gelDescription = `A full-coverage gel that has already been sculpted while off of the hand to create a full
		nail extension with gel when applied. This type of nail extension provides the most natural finish and the most
		uniformed shapes.`
	return (
		<div className='NailServices Services page-content-wrapper'>
			<div className='page-content'>
				<h1>Styles</h1>
				<div className='services-style-gallery'>
					<StyleBox
						title='No-chip manicure'
						imageSrc={noChipExample}
						imageAlt='No-chip nails'
						description={noChipDescription} />
					<StyleBox
						title='Builder gel'
						imageSrc={builderExample}
						imageAlt='Builder gel nails'
						description={builderDescription} />
					<StyleBox
						title='Acrylic powder'
						imageSrc={acrylicExample}
						imageAlt='Acrylic powder nails'
						description={acrylicDescription} />
					<StyleBox
						title='Gel X'
						imageSrc={gelExample}
						imageAlt='Gel X nails'
						description={gelDescription} />
				</div>
			</div>
		</div>
	)
}