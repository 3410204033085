import React from 'react'

export default function Error() {
    return (
        <div className='Error' style={{marginInline: '5%', marginBlockStart: 60}}>
            <h2 align='center'>Oops!</h2>
            <h4 align='center'>This page does not exist.</h4>
        </div>
    )
}
