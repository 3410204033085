import React from 'react'
import './EmbeddedSchedule.scss'

export default function EmbeddedSchedule() {
	React.useEffect(() => {
		const script = document.createElement('script')
		script.src = "https://embed.acuityscheduling.com/js/embed.js"
		script.async = true
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	})

	return (
		<div className='EmbeddedSchedule'>
			<iframe
				id='embedded-schedule-iframe'
				title='Embedded Acuity scheduler'
				src='https://sweetheartbeautybar.acuityscheduling.com/schedule.php' style={{
			}}/>
		</div>
	)
}



